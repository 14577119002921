import React from 'react'
import './Button.css'

function Button() {
  return (
    <div class="login-box">
    
    <form>
      <a href='/ExpertiseSect'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        Explore Expertise
      </a>
    </form>
  </div>
  )
}

export default Button