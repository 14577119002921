
import cwgLogo from "../assets/cwgLogo.png";
import OurProfileBg from '../assets/OurProfileBg.jpeg';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";






export default function OurProfile() {
  return (
    <>
      
      <Navbar/> 
      <main className="profile-page">
        <section className="relative block" style={{ height: "500px" }}>
        <div
            className="absolute top-0 w-full h-full bg-center bg-cover"  >
              <img src= {OurProfileBg } alt="ProfileBg"/>
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={cwgLogo}
                        className="  h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-64"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>

                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 ">
                  Our Profile
                  </h3>
                  <div
  className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase"
  style={{ fontFamily: '"Times New Roman", Times, serif' }}
>
  <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
  Telephone: +263 (242) 794 930/ 704 933/ 704 935 | Fax 263-4-796903 Offices:
  Cecil House, 2 Ahmed Ben Bella (Formerly Central Avenue), Harare, Zimbabwe |
  email@cwg.co.zw | Web: www.cwg.co.zw
</div>

                  <div className="mb-2 text-gray-700">
                    <i className="fas fa-university mr-2 text-lg text-gray-500"></i>
                  </div>
          
                </div>
                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12  text-left px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                      <b>Coghlan Welsh & Guest</b> is one of the oldest and largest law firms in  in Zimbabwe . The firm was established under its present name in 1911, having been in existence under its predecessors in title since the 1890s.
                        
                        <span> <br/></span>
                        <span> <br/> </span>
                        On the  1st  of January 2005, Coghlan Welsh & Guest merged with the firm Stumbles & Rowe, to form one of the largest law partnerships in Zimbabwe. Currently the firm has a compliment of sixty staff members with the professional qualified personnel listed below. The firm maintains a single office in the central business district of Harare, Zimbabwe.                        <br></br>
                        <br></br>
                        The firm provides a comprehensive range of legal services and solutions for domestic, regional, and international clients. The firm is reputed as one of the leading and seasoned firms in Zimbabwe and is distinguished by its clients and various other stakeholders in the commercial and legal sectors.
                        <br></br>
                        <br></br>
                        The firm thrives on principles of confidentiality, professionalism, quality service and integrity. The firm approaches its projects with enthusiasm and commitment. The firm’s work process is team based with membership built on experience, expertise, and seniority to provide the highest possible standard of legal service.
                      
                        <br></br>
                        <br></br>
                        The firm has also developed networks and close relations with major law firms globally, including the United States of America, the United Kingdom and in the region. The firm is a member of the Loan Market Association.
                        </p>
                      
                   
                    </div>
                    <div className="justify-center items-center w-full mx-12 ">
                      <h3 className="underline-offset-1 underline  font-bold justify-center items-center  ">CLIENTELE</h3>
                      <p>
                      The firm advises a wide spectrum of clients including listed companies, individuals, Quasi Government Organizations, Embassies, corporate entities both local and international, insurance companies, energy companies (in oil, electricity, and gas), local, regional, international Banks (commercial, merchant, developmental and the central bank),  
                      </p>
                    </div>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
      
    </>
  );
}