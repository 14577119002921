import React from 'react';
import Insights from '../components/Insights';
import Footer2 from '../components/Footer2';

function InsightsPage() {
  return (
    <>
    
    <main >
    <Insights/>
    </main>
    <Footer2/>
    </>
    
  )
};

export default InsightsPage;