import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import React from "react";
import { useSwiper } from "swiper/react";

export const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns flex gap-4">
    <button
      className="flex justify-center items-center rounded-full bg-black text-white h-8 w-8"
      onClick={() => swiper.slidePrev()}
    >
      <span className="sr-only">Previous slide</span>
      <ChevronLeftIcon className="h-6 w-6" />
    </button>
    <button
      className="flex justify-center items-center rounded-full bg-black text-white h-8 w-8"
      onClick={() => swiper.slideNext()}
    >
      <span className="sr-only">Next slide</span>
      <ChevronRightIcon className="h-6 w-6" />
    </button>
  </div>
  
  );
};
