import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Feed = ({ title, link, date, creator, contentSnippet }) => {
  let formatted = { day: "numeric", month: "long", year: "numeric" };
  let articleDate = new Date(date).toLocaleDateString("en-GB", formatted);
  return (
    
      <div class="relative flex flex-wrap items-center w-full px-5  mx-auto md:px-12 lg:px-24 max-w-7xl">
        <div class="grid w-full grid-cols-1 gap-6 mx-auto lg:grid">
          <div class="p-6">
            <h2 class="mb-2 text-xs font-semibold tracking-widest text-blue-600 uppercase">
              {date}
            </h2>
            <h1 class="mx-auto mb-2 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl">
              {title}
            </h1>
            <p class="mx-auto max-h-12 overflow-hidden text-base font-medium leading-relaxed text-gray-500">
              {" "}
              {contentSnippet}
            </p>
            <p class="mx-auto text-base font-medium leading-relaxed text-gray-500">
              {" "}
              {creator}
            </p>
            <div class="mt-2">
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                class="inline-flex items-center  font-semibold text-blue-600 lg:mb-0 hover:text-neutral-600"
                title="read more"
              >
                {" "}
                Read More »{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
  );
};
export default Feed;
