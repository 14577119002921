import React, { useEffect, useState } from "react";
import axios from "axios";
import Feed from "./Feed";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SwiperNavButtons } from "./SwiperNavButton";

function RssArticles() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  const getArticles = async () => {
    try {
      const res = await axios.get("https://welsh-rss.onrender.com");
      setArticles(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-40">
          <p className="text-black text-2xl">Loading latest news...</p>
        </div>
      ) : (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          scrollbar={{ draggable: true }}
          autoplay={{ delay: 10000 }}
          className="mySwiper px-4 text-xs"
        >
          {articles.map((item, i) => (
            <SwiperSlide key={i}>
              <Feed
                title={item.item.title}
                link={item.item.link}
                date={item.item.pubDate}
                creator={item.item.creator}
                contentSnippet={item.item.contentSnippet}
                content={item.item.content}
              />
            </SwiperSlide>
          ))}
          <div className="mb-4 w-full flex justify-center">
            <SwiperNavButtons />
          </div>
        </Swiper>
      )}
    </div>
  );
}

export default RssArticles;
